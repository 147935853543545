import logo from '../assets/images/Logo.png';


export default function Header() {
    return (
        <header className="top-0 z-50 bg-white shadow-lg">
        <nav className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="https://bociany.zywocice.pl" className="mx-auto md:ml-0">
                <img
                    className="h-12 w-auto"
                    src={logo}
                    alt=""
                />
            </a>
            {/* <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 mx-auto md:mr-0">
                <li className='flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#049bcf" className="w-6 h-6 my-auto mx-1">
                        <path strokeLinecap="round" strokeLinejoin="round"  d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                    </svg>
                    <a href="https://mapa.ptaki-silesiana.pl/#" target='_blank' rel="noreferrer" className="block py-2 pr-4 pl-3 text-gray-800 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-primary-700 lg:p-0 underline underline-offset-2 decoration-2 decoration-main-blue">Gdzie lecą nasze bociany?</a>
                </li>
            </ul> */}
        </nav>
      </header>
    );
}