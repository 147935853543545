import './App.css';
import React from 'react';
import Header from "./partials/header";
import Footer from "./partials/footer";
import { Helmet } from "react-helmet";
import bocian from "./assets/images/bocian-na-urlopie.png";
import { VideoJS }  from './components/videojs';

function App() {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    language: 'pl',
    normalizeAutoplay: true,
    fluid: true,
    controlBar: {
      'pictureInPictureToggle': true,
      'volumePanel': false,
    },
    sources: [{
      src: 'https://iphort.ksinternet.pl/live/zywocice/playlist.m3u8',
      type: 'application/x-mpegURL'
    }]
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };
  return (
    <>
      <Helmet>‍
        <meta property="og:title" content="Bociany Żywocice - Kamera Live" />
        <meta property="og:url" content="bociany.zywocice.pl" />
        <meta property="og:site_name" content="Bociany Żywocice" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="App">
      <main>
          <Header />
          <div className="max-w-screen-xl m-auto">
            <h1 className="my-16 font-semibold text-2xl text-center text-gray-800">Żywocickie bociany on-line - Kamera Live</h1>
          </div>
          <div className="mx-2 md:mx-auto md:max-w-screen-md my-12 border-b-4 border-main-green">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} className="" />
            {/* <img src={bocian} alt="Bocian na urlopie" /> */}
          </div>
          <Footer />
      </main>
      </div>
    </>

  );
}

export default App;
